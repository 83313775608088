import { fetchRecords, postData, deleteRecord } from 'helpers/Helper'
import { API_URL } from 'helpers/Constants'
//import { addNotification as notify } from 'reapop'

// eslint-disable-next-line
import {
	GET_UNCONFIRMED_ORDERS,
	ADD_UNCONFIRMED_ORDER,
	LOCK_ORDERS,
	DELETE_UNCONFIRMED_ORDER,
	SET_ORDERS_DATE,
	FETCHING_UORDERS,
	RESET_UNCONFIRMED_ORDERS,
	SET_ACTIVE_ORDERS_STORE,
} from './types'

//Request UserData
export const getUncOrders = (
	callback = false,
	partnerContext = null
) => async dispatch => {
	let url =
		partnerContext && partnerContext.hash
			? `${API_URL}/unconfirmedOrders?filters=for${partnerContext.type.replace(
					' ',
					''
			  )}(${partnerContext.hash})&limit=-1`
			: `${API_URL}/unconfirmedOrders?limit=-1`
	let options = { url: url }

	//Set Fetching User flag to avoid multiple request
	dispatch({ type: FETCHING_UORDERS, payload: true })

	const response = await fetchRecords(options)

	if (response !== null) {
		dispatch({ type: GET_UNCONFIRMED_ORDERS, payload: response.data.data })
	}

	//Set Fetching User flag to avoid multiple request
	dispatch({ type: FETCHING_UORDERS, payload: false })

	if (callback) {
		callback()
	}
	return response
}

export const createOrder = (
	data,
	notify,
	callback = null,
	meta = {},
) => async dispatch => {
	let options = {
		url: `${API_URL}/order`,
		method: 'post',
		data
	}

	let response = await postData(options, notify)

	if (response !== null) {
		dispatch({ type: ADD_UNCONFIRMED_ORDER, payload: response.data.data })

		if (callback) {
			callback(true, 'create', response, meta)
		}
		let notification = {
			title: 'Success',
			message: response.data.data.notification_message,
			status: 'success',
			position: 'tr',
			dismissible: true,
			dismissAfter: 3000
		}

		if (notify) {
			notify(notification)
		}
	} else {
		if (callback) {
			callback(false, 'create', response, meta)
		}
	}
}

export const confirmOrders = (
	storeHash,
	notify,
	storeHashes = [],
	omitStoreEmails = false
) => async dispatch => {
	let options = {
		url: `${API_URL}/store/${storeHash}/lockOrders`,
		method: 'post',
		data: { storeHashes: storeHashes, omitStoreEmails }
	}

	let response = await postData(options, notify)

	if (response !== null) {
		dispatch({ type: LOCK_ORDERS, payload: response.data })
		return response.status
	}

	return false
}

export const setOrdersDate = (
	store_hash,
	order_hashes,
	date_required
) => async dispatch => {
	let options = {
		url: `${API_URL}/admin/store/${store_hash}/setOrdersDate`,
		method: 'post',
		data: { date_required, order_hashes }
	}

	let response = await postData(options)

	if (response !== null) {
		dispatch({
			type: SET_ORDERS_DATE,
			payload: response.data.data
		})
		return response.data.data
	}
	return false
}

export const deleteOrder = (orderHash, notify, callback) => async dispatch => {
	let options = {
		url: `${API_URL}/order/${orderHash}`
	}

	let response = await deleteRecord(options, notify)

	if (response !== null) {
		dispatch({
			type: DELETE_UNCONFIRMED_ORDER,
			payload: response.data.data.hashid
		})
		if (callback) {
			callback(true, 'delete', response)
		}
	} else {
		if (callback) {
			callback(false, 'delete')
		}
	}
}

export const resetUncOrders = () => async dispatch => {
	dispatch({
		type: RESET_UNCONFIRMED_ORDERS,
		payload: null
	})
}

export const setActiveOrdersStore = (store) => async dispatch => {
	dispatch({
		type: SET_ACTIVE_ORDERS_STORE,
		payload: store
	})
}
